// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-about-js": () => import("/opt/build/repo/src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-home-js": () => import("/opt/build/repo/src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-production-js": () => import("/opt/build/repo/src/templates/production.js" /* webpackChunkName: "component---src-templates-production-js" */),
  "component---src-pages-contactez-nous-js": () => import("/opt/build/repo/src/pages/contactez-nous.js" /* webpackChunkName: "component---src-pages-contactez-nous-js" */),
  "component---src-pages-nos-productions-js": () => import("/opt/build/repo/src/pages/nos-productions.js" /* webpackChunkName: "component---src-pages-nos-productions-js" */),
  "component---src-pages-regoinez-nous-js": () => import("/opt/build/repo/src/pages/regoinez-nous.js" /* webpackChunkName: "component---src-pages-regoinez-nous-js" */),
  "component---src-pages-thanks-js": () => import("/opt/build/repo/src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */)
}

exports.data = () => import("/opt/build/repo/.cache/data.json")

